/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-01-21 16:31:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-04 09:49:06
 */
export const columns = [
  {
    title: '大区',
    dataIndex: 'bigAreaName',
    key: 'bigAreaName',
    align: 'center',
    fixed: "left",
    width: 120
  },
  {
    title: '事务所',
    dataIndex: 'firmName',
    key: 'firmName',
    align: 'center',
    fixed: "left",
    width: 120,
  },
  {
    title: '经销商',
    dataIndex: 'dealerName',
    key: 'dealerName',
    align: 'center',
    fixed: "left",
    width: 250,
    scopedSlots: {
      customRender: 'dealerName'
    }
  },
  {
    title: '流水号',
    dataIndex: 'limitCode',
    key: 'limitCode',
    align: 'center',
    scopedSlots: {
      customRender: 'limitCode'
    },
    width: 300
  },
  {
    title: '额度名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 150
  },
  {
    title: '额度金额',
    dataIndex: 'limitAmount',
    key: 'limitAmount',
    align: 'center',
    scopedSlots: {
      customRender: 'amount'
    },
    width: 120
  },
  {
    title: '剩余额度',
    dataIndex: 'remainingAmount',
    key: 'remainingAmount',
    align: 'center',
    scopedSlots: {
      customRender: 'amount'
    },
    width: 120

  },
  {
    title: '额度类型',
    dataIndex: 'limitType',
    key: 'limitType',
    align: 'center',
    scopedSlots: {
      customRender: 'limitType'
    },
    width: 100
  },
  {
    title: '有效时间',
    dataIndex: 'validityStart',
    key: 'validityStart',
    align: 'center',
    scopedSlots: { customRender: 'validityStart' },
    width: 200
  },
  {
    title: '审核状态',
    dataIndex: 'authStatus',
    key: 'authStatus',
    align: 'center',
    scopedSlots: { customRender: 'authStatus' },
    width: 100
  },
  {
    title: '额度状态',
    dataIndex: 'limitStatus',
    key: 'limitStatus',
    align: 'center',
    scopedSlots: { customRender: 'limitStatus' },
    width: 100
  },
  // {
  //   title: '禁启用',
  //   dataIndex: 'flagEnable',
  //   key: 'flagEnable',
  //   align: 'center',
  //   scopedSlots: { customRender: 'flagEnable' },
  //   width: 100
  // },
  {
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    align: 'center',
    width: 120
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'center',
    width: 200
  },
]
